var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [_c("q-btn-group", { attrs: { outline: "" } })],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "TBM번호",
                            name: "tbmNo",
                          },
                          model: {
                            value: _vm.data.tbmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmNo", $$v)
                            },
                            expression: "data.tbmNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            afterIcon:
                              _vm.editable && !_vm.disabled
                                ? [
                                    {
                                      name: "search",
                                      click: true,
                                      callbackName: "searchWorkPermit",
                                    },
                                    {
                                      name: "close",
                                      click: true,
                                      callbackName: "removeWorkPermit",
                                    },
                                  ]
                                : null,
                            editable: _vm.editable,
                            readonly: true,
                            label: "작업허가번호",
                            name: "permitNo",
                          },
                          on: {
                            searchWorkPermit: _vm.searchWorkPermit,
                            removeWorkPermit: _vm.removeWorkPermit,
                          },
                          model: {
                            value: _vm.data.permitNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "permitNo", $$v)
                            },
                            expression: "data.permitNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM명",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.data.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmName", $$v)
                            },
                            expression: "data.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업일",
                            type: "date",
                            name: "tbmDate",
                          },
                          model: {
                            value: _vm.data.tbmDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmDate", $$v)
                            },
                            expression: "data.tbmDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            label: "부서",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.data.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorCd", $$v)
                            },
                            expression: "data.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            label: "공정",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.data.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "processCd", $$v)
                            },
                            expression: "data.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: _vm.isWorkPermit || _vm.disabled,
                            editable: _vm.editable,
                            label: "작업",
                            name: "workName",
                          },
                          model: {
                            value: _vm.data.workName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workName", $$v)
                            },
                            expression: "data.workName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업책임자",
                            name: "tbmLeaderId",
                          },
                          model: {
                            value: _vm.data.tbmLeaderId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmLeaderId", $$v)
                            },
                            expression: "data.tbmLeaderId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-construnction", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            name: "sopConstructionId",
                          },
                          model: {
                            value: _vm.data.sopConstructionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sopConstructionId", $$v)
                            },
                            expression: "data.sopConstructionId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "TBM_PRE_INSPECTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "사전점검",
                            name: "preInspections",
                          },
                          model: {
                            value: _vm.data.preInspections,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "preInspections", $$v)
                            },
                            expression: "data.preInspections",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업내용",
                            name: "tbmContents",
                          },
                          model: {
                            value: _vm.data.tbmContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "tbmContents", $$v)
                            },
                            expression: "data.tbmContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "특이사항",
                            name: "significant",
                          },
                          model: {
                            value: _vm.data.significant,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "significant", $$v)
                            },
                            expression: "data.significant",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-table",
                {
                  ref: "riskTable",
                  attrs: {
                    title: "위험요인 목록",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    data: _vm.data.tbmRiskList,
                    isTitle: true,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "위험요인을 추가하세요.",
                    gridHeightAuto: true,
                    hideBottom: false,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmRiskId",
                    selection: "multiple",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "tbmJobStep"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmJobStep"],
                                        callback: function ($$v) {
                                          _vm.$set(props.row, "tbmJobStep", $$v)
                                        },
                                        expression: "props.row['tbmJobStep']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmJobStep"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmRiskHazard"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmRiskHazard"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmRiskHazard",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmRiskHazard']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmRiskHazard"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmImprovementMeasures"
                            ? [
                                props.row["tbmPermitFlag"] === "N"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value:
                                          props.row["tbmImprovementMeasures"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmImprovementMeasures",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmImprovementMeasures']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row["tbmImprovementMeasures"]
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "추가",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addRisk },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.data.tbmRiskList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "제외",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeRisk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-table",
                {
                  ref: "attendeeTable",
                  attrs: {
                    title: "참석자 및 건강상태 목록",
                    columns: _vm.grid2.columns,
                    gridHeight: _vm.grid2.height,
                    data: _vm.data.tbmAttendeeList,
                    isTitle: true,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "참석자가 없습니다.",
                    gridHeightAuto: true,
                    hideBottom: false,
                    editable: _vm.editable && !_vm.disabled,
                    selection: "multiple",
                    rowKey: "tbmAttendeeId",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "tbmAttendeeUserName"
                            ? [
                                props.row["tbmDirectFlag"] === "Y"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmAttendeeUserName"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmAttendeeUserName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmAttendeeUserName']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row["tbmAttendeeUserName"]
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmAttendeeJobName"
                            ? [
                                props.row["tbmDirectFlag"] === "Y"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmAttendeeJobName"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmAttendeeJobName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmAttendeeJobName']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row["tbmAttendeeJobName"]
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "click"
                            ? [
                                _c(
                                  "q-btn",
                                  {
                                    attrs: {
                                      round: "",
                                      unelevated: "",
                                      size: "10px",
                                      color: "amber",
                                      icon: "mode",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return (() => {
                                          ;(_vm.rowIndex = props.rowIndex),
                                            (_vm.electronSignature =
                                              props.row.electronSignature)
                                        }).apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "q-popup-proxy",
                                      {
                                        ref: "proxy_" + props.rowIndex,
                                        attrs: { breakpoint: 400 },
                                      },
                                      [
                                        _c(col.component, {
                                          tag: "component",
                                          attrs: {
                                            outFlag: true,
                                            popupParam: props.row,
                                            data: _vm.data,
                                            rowIndex: props.rowIndex,
                                          },
                                          on: { callback: _vm.callback },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "선택",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee1 },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "직접",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee2 },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.data.tbmAttendeeList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeAttendee },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable && !_vm.disabled,
                  label: "교육자료",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.popupParam.tbmId,
                      expression: "editable && !popupParam.tbmId",
                    },
                  ],
                  attrs: { flat: true, label: "불러오기", icon: "save_alt" },
                  on: { btnClicked: _vm.copyTbm },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.editable && _vm.popupParam.tbmId && !_vm.disabled,
                      expression: "editable && popupParam.tbmId && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    label: "삭제",
                    editable: _vm.editable,
                    icon: "delete_forever",
                  },
                  on: { btnClicked: _vm.remove },
                }),
                _c("c-appr-btn", {
                  ref: "appr-btn",
                  attrs: {
                    flat: true,
                    name: "danger-journal-appr-btn",
                    editable: _vm.editable,
                    approvalInfo: _vm.approvalInfo,
                  },
                  on: {
                    beforeApprAction: _vm.saveDataAppr,
                    callbackApprAction: _vm.approvalCallback,
                    requestAfterAction: _vm.getDetail,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    url: _vm.saveUrl,
                    isSubmit: _vm.isSave,
                    param: _vm.data,
                    mappingType: _vm.saveType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveTbm,
                    btnCallback: _vm.saveCallback,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }